<template>
  <div class="reset">
    <el-row :gutter="20" class="reset-wraps">
      <el-col :span="8" class="reset-lefts">
        <img src="@/assets/password.png" alt="">
      </el-col>
      <el-col :span="16" class="reset-rights">
        <h3>修改密码</h3>
        <p>
          请在下面的输入框中输入对应密码，然后点击“保存”修改您的登陆密码为最新的密码。为了保证您的账号安全，请不要将您的密码告诉给第三方，并建议您不定期修改您的登录密码
        </p>
        <el-form ref="formPhone" :model="loginPhone" class="form-content">
          <el-form-item :error="errors.phone">
            <el-input
              v-model="loginPhone.phone"
              :disabled="loginPhone.phone != ''"
              prefix-icon="el-icon-user-solid"
            ></el-input>
          </el-form-item>
          <el-form-item :error="errors.newPassword">
            <el-input
              v-model="loginPhone.newPassword"
              show-password
              placeholder="请输入密码"
              prefix-icon="el-icon-unlock"
            ></el-input>
          </el-form-item>
          <el-form-item :error="errors.code">
            <el-input
              v-model="loginPhone.code"
              prefix-icon="el-icon-unlock"
              placeholder="请输入验证码"
              @keyup.enter.native="resetPwd"
            >
              <el-button
                slot="append"
                :loading="loading.send"
                @click="sendSMSCode"
                >{{ codeContent }}</el-button
              >
            </el-input>
          </el-form-item>
          <el-form-item class="submit">
            <el-button class="btn-login" type="primary" @click="resetPwd"
              >保存</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <!-- <el-menu mode="horizontal" default-active="1">
      <el-menu-item index="1">修改密码</el-menu-item>
    </el-menu>
    <el-card class="box-card" shadow="never">
      <p>
        请在下面的输入框中输入对应密码，然后点击“提交新密码”修改您的登陆密码为最新的密码。为了保证您的账号安全，请不要将您的密码告诉给第三方，并建议您不定期修改您的登录密码
      </p>
    </el-card>
    <el-form ref="formPhone" :model="loginPhone" class="form-content">
      <el-form-item :error="errors.phone">
        <el-input
          v-model="loginPhone.phone"
          :disabled="loginPhone.phone != ''"
          prefix-icon="el-icon-user-solid"
        ></el-input>
      </el-form-item>
      <el-form-item :error="errors.newPassword">
        <el-input
          v-model="loginPhone.newPassword"
          show-password
          placeholder="请输入密码"
          prefix-icon="el-icon-unlock"
        ></el-input>
      </el-form-item>
      <el-form-item :error="errors.code">
        <el-input
          v-model="loginPhone.code"
          prefix-icon="el-icon-unlock"
          placeholder="请输入验证码"
          @keyup.enter.native="resetPwd"
        >
          <el-button
            slot="append"
            :loading="loading.send"
            @click="sendSMSCode"
            >{{ codeContent }}</el-button
          >
        </el-input>
      </el-form-item>
      <el-form-item class="submit">
        <el-button class="btn-login" type="primary" @click="resetPwd"
          >登录</el-button
        >
      </el-form-item>
    </el-form> -->
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      loginPhone: {
        phone: "",
        newPassword: "",
      },
      loading: {
        send: false,
      },
      codeContent: "获取验证码",
      timerRunning: false,
      errors: {
        code: null,
      },
    };
  },
  computed: {},
  methods: {
    //发送验证码
    sendSMSCode() {
      if (this.loading.send || this.timerRunning) return;
      if (this.checkLoginPhone()) {
        this.loading.send = true;
        this.$store
          .dispatch("login/sendLoginCode", {
            phoneNumber: this.loginPhone.phone,
          })
          .then((data) => {
            this.loading.send = false;
            this.countDownChange();
          })
          .catch((err) => {
            this.loading.send = false;
          });
      }
    },
    //倒计时
    countDownChange() {
      let number = 60;
      this.timerRunning = true;
      this.loading.send = false;
      this.codeContent = "60S后重新获取";
      let timer = setInterval(() => {
        if (number > 1) {
          number--;
          this.codeContent = number + "S后重新获取";
          return true;
        } else {
          number = 60;
          clearInterval(timer);
          this.codeContent = "获取验证码";
          this.timerRunning = false;
          return false;
        }
      }, 1000);
    },
    //修改密码
    resetPwd() {
      if (this.checkLoginPhone()) {
        this.errors.code=''
        this.errors.newPassword=''
        if (!this.loginPhone.newPassword) {
          this.errors.newPassword = "请输入密码";
          return;
        }
        if (!this.loginPhone.code) {
          this.errors.code = "请输入验证码";
          return;
        }
        this.loginState = true;
        this.$store
          .dispatch("login/resetPassword", {
            phone: this.loginPhone.phone,
            newPassword: this.loginPhone.newPassword,
            vcode: this.loginPhone.code,
          })
          .then((data) => {
            if (data == 0) {
              this.$message.success('密码修改成功')
              this.$store.state.login.token=null
              this.$router.replace({
                path: '/login'
              });
            }
          })
          .catch((data) => {
            this.errors.code=''
            this.errors.newPassword=''
            //登录失败重置倒计时重新登录需做
            this.$message.error(data.message);
            this.loginState = false;
          });
      }
    },

    checkLoginPhone() {
      if (!this.loginPhone.phone) {
        this.errors.phone = "请输入手机号";
        return false;
      } else if (!/^1\d{10}$/.test(this.loginPhone.phone)) {
        this.errors.phone = "请输入正确的手机号";
        return false;
      }

      return true;
    },
  },
  created() {
    this.loginPhone.phone = this.$store.state.login.phoneNumber;
  },
};
</script>

<style lang='less' scoped>
.form-content {
  width: 500px;
  // margin-left: 20px;
  padding-bottom: 20px;
}
.box-card {
  border: none;
}
.submit {
  text-align: center;
}

.reset-wraps {
  display: flex;
  margin-top: 88px;
  .reset-lefts {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    img {
      width: 400px;
      // height: 303px;
    }
  }
  .reset-rights {
    h3 {
      margin: 0px;
      padding: 0px;
      position: relative;
      &::before {
        content: '';
        width: 5px;
        height: 20px;
        background: #409EFF;
        position: absolute;
        left: 0px;
        top: 4px;
      }
      padding-left: 15px;
    }
    p {
      width: 80%;
      color: #737B8B;
      font-size: 14px;
      line-height: 22px;
      margin: 20px 0 30px 0;
    }
    .el-form {
      .el-form-item {
        margin-bottom: 30px;
      }
    }
  }
}
</style>